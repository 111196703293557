import { createServiceManifest } from '@estee/elc-service';
import { CartService } from '~setup/CartService';
import { events } from 'elc-service-bus';
import { CART_SIDEBAR } from '@estee/elc-service-view-names';

const { name, version } = __serviceInfo__;
const {
    CART_UPDATED,
    CART_BADGE_CLICKED,
    REMOVE_ITEM,
    OFFERS_LOADED,
    MANUAL_OFFER_CODE_APPLIED
} = events;
export const serviceQueries = [];
export const serviceViews = [CART_SIDEBAR];
export const serviceEvents = [
    CART_UPDATED,
    CART_BADGE_CLICKED,
    REMOVE_ITEM,
    OFFERS_LOADED,
    MANUAL_OFFER_CODE_APPLIED
];

const manifestPayload = {
    queries: serviceQueries,
    events: serviceEvents,
    views: serviceViews,
    name,
    version
};
export const ServiceManifest = createServiceManifest<CartService>(
    manifestPayload,
    () =>
        import(
            /* webpackChunkName: 'core' */
            './service-setup/diContainer'
        ),
    'cartService'
);
